import React from "react";

interface IProps {
  chapter: string;
  chapters: string[];
  setChapter: (ch: string) => void;
}
const ChapterSelector: React.FC<IProps> = ({
  chapter,
  chapters,
  setChapter,
}) => {
  return (
    <select
      className="form-select"
      value={chapter}
      onChange={(e) => setChapter(e.target.value)}
    >
      {chapters.map((c) => (
        <option key={c} value={c}>
          Chapter {c}
        </option>
      ))}
    </select>
  );
};

export default ChapterSelector;
