import React, { useMemo } from "react";
import Chip from "./Chip";
import "./VerseSelector.scss";

interface IVerseSelector {
  verses: string[];
  selectedVerses: string[];
  setSelectedVerses: (verse: string[]) => void;
}
const VerseSelector: React.FC<IVerseSelector> = ({
  verses,
  selectedVerses,
  setSelectedVerses,
}) => {
  const toggleVerse = (n: string) => {
    const newSelected = selectedVerses.includes(n)
      ? selectedVerses.filter((v) => v !== n)
      : [...selectedVerses, n];
    setSelectedVerses(newSelected);
  };

  const checkedMap = useMemo(() => {
    const map = verses.reduce<{ [verse: string]: boolean }>(
      (hash, v) => ({
        ...hash,
        [v]: false,
      }),
      {}
    );
    selectedVerses.forEach((v) => (map[v] = true));
    return map;
  }, [verses, selectedVerses]);

  const partialSelected = verses.length === selectedVerses.length;
  const getAllFn = () => setSelectedVerses(partialSelected ? [] : verses);

  return (
    <div className="k-verse-selector">
      <Chip checked={selectedVerses.length > 0} onClick={getAllFn}>
        {partialSelected ? "x" : "+"}
      </Chip>
      {verses.map((n) => (
        <Chip key={n} checked={checkedMap[n]} onClick={() => toggleVerse(n)}>
          {n}
        </Chip>
      ))}
    </div>
  );
};

export default VerseSelector;
