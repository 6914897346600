import CircularProgress from "@mui/material/CircularProgress";
import "./Layout.scss";

interface ILayoutProps {
  loading?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ loading, children }) =>
  loading ? (
    <div className="loading-container">
      <CircularProgress />
    </div>
  ) : (
    <>{children}</>
  );

export default Layout;
