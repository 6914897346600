import React, { useEffect, useState } from "react";

const STORAGE_VERSION = "1.0.1"; // updating this will auto wipe localstorage
const VERSION_KEY = "koine-local-version";

function useLocalStorage<T>(
  initialValue: T,
  key: string
): [T, React.Dispatch<React.SetStateAction<T>>, boolean] {
  const [state, setState] = useState<T>(initialValue);
  const [initializing, setInit] = useState(true);

  useEffect(() => {
    const localVersion = localStorage.getItem(VERSION_KEY);
    if (!localVersion || localVersion !== STORAGE_VERSION) {
      localStorage.clear();
      localStorage.setItem(VERSION_KEY, STORAGE_VERSION);
    }

    const jsonValue = localStorage.getItem(key);
    if (jsonValue) {
      const value = JSON.parse(jsonValue);
      setState(value);
    }
    setInit(false);
  }, [key]);

  useEffect(() => {
    const jsonState = JSON.stringify(state);
    const localValue = localStorage.getItem(key);

    if (localValue && localValue === jsonState) return;
    localStorage.setItem(key, jsonState);
  }, [state, key]);

  return [state, setState, initializing];
}

export default useLocalStorage;
