import React, { useEffect, useState } from "react";
import Card from "./Card";

import "./App.scss";
import VerseSelector from "./VerseSelector";
import useVocab, { IVocab } from "../hooks/useVocab";
import ChapterSelector from "./ChapterSelector";
import useCollection from "../hooks/useCollection";
import Restore from "@mui/icons-material/Restore";
import Done from "@mui/icons-material/Done";
import Shuffle from "@mui/icons-material/Shuffle";
import SkipNext from "@mui/icons-material/SkipNext";
import FlipIcon from "@mui/icons-material/Flip";
import Chip from "./Chip";
import useLocalStorage from "../hooks/useLocalStorage";
import Layout from "./Layout";

const front: (keyof IVocab)[] = ["word"];
const back: (keyof IVocab)[] = [
  "meaning",
  "type",
  "tense",
  "voice",
  "mood",
  "person",
  "case",
  "gender",
  "number",
];

const App = () => {
  const [vocab, chapterVerses, loading] = useVocab();
  const [state, actions, meta] = useCollection(vocab, chapterVerses);
  const [inverted, setInverted] = useLocalStorage(false, "koine-inverted");
  const [cardPair, setCardPair] = useState([front, back]);

  useEffect(() => {
    const newPair = [[...front], [...back]];
    if (inverted) newPair.reverse();
    newPair[1].push("root");
    setCardPair(newPair);
  }, [inverted]);

  return (
    <Layout loading={loading}>
      <div className="container py-5">
        <div className="header">
          <h1 className="mb-3">Mark flash cards</h1>
          <div className="sub-header">
            {!!state.collection.length && (
              <span>{`${state.idx + 1}/${meta.length}`}</span>
            )}
            <Chip onClick={() => setInverted((v) => !v)} checked={inverted}>
              <FlipIcon />
            </Chip>
          </div>
        </div>
        <div className="card-wrapper">
          <Card
            vocabItem={vocab[state.collection[state.idx]]}
            front={cardPair[0]}
            back={cardPair[1]}
          />
        </div>
        <div className="btn-group d-flex mb-4 main-actions">
          {state.collection.length > 1 ? (
            <button
              className="btn btn-outline-primary"
              onClick={() => actions.learnt()}
              title="Learnt"
            >
              <Done />
            </button>
          ) : (
            <button
              className="btn btn-outline-primary"
              onClick={actions.reset}
              title="Restart current set"
            >
              <Shuffle />
            </button>
          )}
          <button
            className="btn btn-outline-primary"
            onClick={actions.undo}
            disabled={!meta.canUndo}
            title="Undo"
          >
            <Restore />
          </button>
          <button
            className="btn btn-outline-primary"
            onClick={actions.next}
            title="Draw next card"
          >
            <SkipNext />
          </button>
        </div>

        <div className="mb-4">
          <ChapterSelector
            chapter={state.chapter || ""}
            setChapter={actions.setChapter}
            chapters={Object.keys(chapterVerses)}
          />
        </div>
        <VerseSelector
          verses={chapterVerses[state.chapter || ""] || []}
          selectedVerses={state.selectedVerses || []}
          setSelectedVerses={actions.setSelectedVerses}
        />
      </div>
    </Layout>
  );
};

export default App;
