import { useCallback, useEffect } from "react";
import useHistoryCache from "./useHistoryCache";
import useLocalStorage from "./useLocalStorage";
import { IChapterVerses, IVocabMap } from "./useVocab";

export interface IActions {
  next: () => void;
  undo: () => void;
  learnt: () => void;
  reset: () => void;
  setChapter: (ch: string) => void;
  setSelectedVerses: (verses: string[]) => void;
}

export interface ISetMetaProps {
  idx: number;
  length: number;
  canUndo: boolean;
}

interface IState {
  collection: string[];
  passed: string[];
  idx: number;
  chapter?: string;
  selectedVerses?: string[];
}

interface IMeta {
  length: number;
  canUndo: boolean;
}

const defaultState: IState = {
  passed: [],
  idx: 0,
  collection: [],
};

const shuffle = (set: string[], n = 5): string[] => {
  const shuffled = [...set].sort(() => (Math.random() > 0.5 ? 1 : -1));
  return n === 0 ? shuffled : shuffle(shuffled, --n);
};

const useCollection = (
  vocab: IVocabMap,
  chapterVerses: IChapterVerses
): [IState, IActions, IMeta] => {
  const [state, setState, initializing] = useLocalStorage<IState>(
    defaultState,
    "koine-state"
  );
  const history = useHistoryCache<IState>(state, setState, initializing);
  const { clear, length, undo } = history;
  const { idx, collection, chapter, passed } = state || defaultState;

  const setPartialState = (n: Partial<IState>) =>
    setState((v) => ({ ...v, ...n }));

  const next = () =>
    idx === collection.length - 1
      ? setPartialState({ idx: 0, collection: shuffle(collection) })
      : setPartialState({ idx: idx + 1 });

  const learnt = () => {
    const nextPassed = [collection[idx], ...passed];
    const nextSet = collection.slice(0, idx).concat(collection.slice(idx + 1));

    idx === collection.length - 1
      ? setPartialState({
          idx: 0,
          collection: shuffle(nextSet),
          passed: nextPassed,
        })
      : setPartialState({
          passed: nextPassed,
          collection: nextSet,
        });
  };

  const reset = () =>
    setPartialState({
      idx: 0,
      collection: shuffle([...collection, ...passed]),
      passed: [],
    });

  const setChapter = useCallback(
    (ch: string) => {
      const nextCollection = Object.keys(vocab).filter(
        (word) => vocab[word].chapter === ch
      );
      setState({
        collection: shuffle(nextCollection),
        passed: [],
        idx: 0,
        selectedVerses: chapterVerses[ch],
        chapter: ch,
      });
      clear();
    },
    [chapterVerses, setState, vocab, clear]
  );

  const setSelectedVerses = (verses: string[]) => {
    const nextCollection = Object.keys(vocab).filter(
      (word) =>
        vocab[word].chapter === chapter && verses.includes(vocab[word].verse)
    );
    setPartialState({
      collection: shuffle(nextCollection),
      idx: 0,
      passed: [],
      selectedVerses: verses,
    });
  };

  useEffect(() => {
    if (initializing || state.chapter) return;
    setChapter(Object.keys(chapterVerses)[0]);
  }, [state, setChapter, chapterVerses, initializing]);

  return [
    state || defaultState,
    {
      next,
      undo,
      learnt,
      reset,
      setChapter,
      setSelectedVerses,
    },
    { length: collection.length, canUndo: length > 1 },
  ];
};

export default useCollection;
