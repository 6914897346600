import axios from "axios";

import { useEffect, useState } from "react";
import parseCSVToArray from "../utils/parseCSVToArray";
import trim from "lodash/trim";

const headings = [
  "chapter",
  "verse",
  "word",
  "meaning",
  "type",
  "tense",
  "voice",
  "mood",
  "person",
  "number",
  "root",
  "case",
  "gender",
  "included",
];

export interface IVocab {
  chapter: string;
  verse: string;
  word: string;
  meaning?: string;
  type?: string;
  tense?: string;
  voice?: string;
  mood?: string;
  person?: string;
  number?: string;
  root?: string;
  case?: string;
  gender?: string;
  included?: string;
}

const csvUri =
  "https://docs.google.com/spreadsheets/d/1qzlX9KtqDwSQbxHijfAAzR3tXim11d3CNp1CVU-OKEw/export?format=csv";
// const csvUri = "http://localhost:8080";

const parseVocab = (row: string[]): IVocab =>
  headings.reduce((map, item, i) => {
    map[item] = trim(row[i]);
    return map;
  }, {} as IVocab);

export interface IVocabMap {
  [word: string]: IVocab;
}
const parseVocabCsv = (data: string): IVocabMap =>
  parseCSVToArray(data)
    .slice(1)
    .map((row) => parseVocab(row))
    .filter((row) => row.included)
    .reduce((map, item) => {
      map[item.word] = item;
      return map;
    }, {} as IVocabMap);

export interface IChapterVerses {
  [ch: string]: string[];
}
const useVocab = (): [
  IVocabMap,
  IChapterVerses,
  boolean,
  string | undefined
] => {
  const [vocab, setVocab] = useState<IVocabMap>({});
  const [chapters, setChapters] = useState<IChapterVerses>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    axios
      .get(csvUri)
      .then((res) => {
        const parsed = parseVocabCsv(res.data);
        const chapterMap = Object.values(parsed).reduce<IChapterVerses>(
          (map, item) => {
            if (!map[item.chapter]) map[item.chapter] = [];
            if (!map[item.chapter].includes(item.verse)) {
              map[item.chapter].push(item.verse);
            }
            return map;
          },
          {}
        );
        Object.keys(chapterMap).forEach((ch) => {
          chapterMap[ch] = chapterMap[ch].sort((a, b) => +a - +b);
        });
        setChapters(chapterMap);
        setVocab(parsed);
      })
      .catch(() => setError("Failed to fetch data"))
      .finally(() => setLoading(false));
  }, []);

  return [vocab, chapters, loading, error];
};

export default useVocab;
