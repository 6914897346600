import { useCallback, useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";

export interface IHistoryConfig {
  size: number;
}

const defaultConfig: IHistoryConfig = {
  size: 15,
};

export interface IHistory {
  clear: (keepCurrent?: boolean) => void;
  undo: () => void;
  length: number;
}

function useHistoryCache<T>(
  state: T,
  setState: React.Dispatch<React.SetStateAction<T>>,
  bypass: boolean,
  config?: IHistoryConfig
): IHistory {
  const conf = useMemo(() => ({ ...defaultConfig, ...config }), [config]);

  const [history, setHistory] = useState<T[]>([]);

  useEffect(() => {
    if (bypass || isEqual(state, history[0])) return;
    setHistory((h) => [state, ...h].slice(0, conf.size));
  }, [state, conf.size, bypass, history]);

  const undo = () => {
    const previous = history[1];
    if (!previous) return;
    setHistory((h) => h.slice(2));
    setState(previous);
  };

  const clear = useCallback(
    (keepCurrent: boolean = true) =>
      setHistory((h) => h.slice(keepCurrent ? 1 : 0)),
    []
  );

  return { undo, clear, length: history.length };
}

export default useHistoryCache;
