import React from "react";
import "./Chip.scss";

interface IChip {
  checked: boolean;
  onClick: () => void;
}

const Chip: React.FC<IChip> = ({ children, checked, onClick }) => (
  <div onClick={onClick} className={`k-chip ${checked ? "checked" : ""}`}>
    <div>{children}</div>
  </div>
);

export default Chip;
